export const StaticConfig: any[] = [
  {
    type: 'list',
    dynamic: true,
    name: 'date_group',
    dependon: ['calendar'],
    title: ['label'],
    property: 'date_groups',
    placeholder: 'Select Date Group',
    rootClass: 'filter__item flex4',
    settings: {
      multi: true,
      listDoneButton: false,
      listStyleType: 'none',
      remote: true,
      showTitle: true,
    },
    icons: {
      leftIcon: '',
    },
    localStorageKey: 'gbf_date_group',
    values: [],
  },
  {
    type: 'list',
    dynamic: true,
    name: 'disposition',
    title: ['label'],
    property: 'dispositions',
    placeholder: 'Select Disposition',
    rootClass: 'filter__item flex4',
    settings: {
      multi: true,
      listDoneButton: false,
      listStyleType: 'none',
      remote: true,
      showTitle: true,
    },
    icons: {
      leftIcon: '',
    },
    localStorageKey: 'gbf_disposition',
    values: [],
  },
  {
    type: 'list',
    dynamic: true,
    name: 'version_rg',
    title: ['label'],
    property: 'version_rg',
    placeholder: 'Select Version',
    rootClass: 'filter__item flex4',
    settings: {
      multi: false,
      listDoneButton: false,
      listStyleType: 'none',
      remote: true,
      showTitle: true,
    },
    icons: {
      leftIcon: '',
    },
    localStorageKey: 'gbf_version_rg',
    values: [{ label: 'V21', value: 'V21' }],
  },
];

export const StaticPageConfig = {
  '/insights/rg': {
    filter: ['date_group', 'disposition', 'version_rg'],
    tabs: [''],
  },
};

export const StaticReturnFilter = {
  disposition: {
    name: 'getDispositions',
    signature: 'method',
  },
  date_group: {
    name: 'getDateGroups',
    signature: 'method',
  },
  versionn: {
    name: 'getUpdatedVersion',
    signature: 'method',
  },
};

export const StaticIntialState: any = {
  disposition: [],
  date_group: [],
  version_rg: { label: 'V21', value: 'V21' },
};

export const getGbfStaticConfig = (name: string) => {
  return Object.assign(
    {},
    StaticConfig.find((x: any) => x.name === name)
  );
};

export const GlobalFilterNames = {
  Disposition: 'disposition',
  DateGroup: 'date_group',
  Version: 'version_rg',
};
